<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">ผู้จำหน่ายยาและเวชภัณฑ์</h4>
      </div>
      <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="3">
            <v-text-field
              v-model="filter_search"
              label="พิมพ์คำค้นหา"
              single-line
              hide-details
            >
              <v-icon
                slot="prepend"
                color="#182839"
              >
                mdi-magnify
              </v-icon>
            </v-text-field>
          </v-col>
          <v-spacer/>
          <div class="d-flex align-end justify-end">
            <v-btn
              :loading="loader"
              :disabled="loader"
              color="blue-grey"
              class="my-3 white--text"
              @click="addItem"
            >
              <v-icon
                left
                dark
              >
                mdi-plus-circle
              </v-icon>
              เพิ่มผู้จำหน่ายยาและเวชภัณฑ์
            </v-btn>
          </div>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="filter_search"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-center">{{item.product_dealer}}</td>

          <td class="d-flex justify-center">
            <v-switch
              v-model="item.product_status_bool"
              inset
              dense
            ></v-switch>
          </td>

          <td class="text-center">
            <v-btn
              icon
              @click="viewItem(item)"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="editItem(item)"
            >
              <v-icon>mdi-file-edit-outline</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="deleteItem(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="fetchData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="showDialog" max-width="500px">
      <v-card>
        <v-card-title>{{boolAddItems ? 'เพิ่มข้อมูลผู้จำหน่ายยาและเวชภัณฑ์' : boolViewItems ? 'ข้อมูลผู้จำหน่ายยาและเวชภัณฑ์' : boolEditItems ? 'แก้ไขข้อมูลผู้จำหน่ายยาและเวชภัณฑ์' : 'ลบข้อมูลผู้จำหน่ายยาและเวชภัณฑ์'}}</v-card-title>
        <v-card-text v-if="boolAddItems">
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อผู้จำหน่าย:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-text-field
                v-model="dataDialog.product_dealer"
                label="ชื่อผู้จำหน่าย"
                placeholder="ระบุชื่อผู้จำหน่าย"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="boolViewItems">
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อผู้จำหน่าย:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{dataDialog.product_dealer}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">สถานะ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-switch
                v-model="dataDialog.product_status_bool"
                inset
                dense
                disabled
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="boolEditItems">
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อผู้จำหน่าย:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-text-field
                v-model="dataDialog.product_dealer"
                label="ชื่อผู้จำหน่าย"
                placeholder="ระบุชื่อผู้จำหน่าย"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">สถานะ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-switch
                v-model="dataDialog.product_status_bool"
                inset
                dense
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <span>{{`ต้องการลบผู้จำหน่าย ${dataDialog.product_dealer || ''} นี้ใช่หรือไม่`}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!boolViewItems" color="error" text @click="closeDialog">
            <v-icon v-if="!boolDelItems" class="mr-3">mdi-close-circle</v-icon>
            ยกเลิก
          </v-btn>
          <v-btn
            :color="boolViewItems ? 'blue darken-1' : 'success'"
            text
            @click="submitDialog"
            :disabled="!cansave"
          >
            <v-icon v-if="!boolViewItems && !boolDelItems" class="mr-3">mdi-content-save</v-icon>
            {{boolViewItems ? 'ปิด' : boolAddItems ? 'เพิ่ม' : boolEditItems ? 'บันทึก' : 'ยืนยัน'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import swal from 'sweetalert'

export default {
  data () {
    return {
      loader: null,
      loaddata: false,
      cansave: true,
      filter_search: '',
      showDialog: false,
      dataDialog: {},
      boolAddItems: false,
      boolViewItems: false,
      boolEditItems: false,
      boolDelItems: false,
      data: []
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'ชื่อผู้จำหน่าย',
          align: 'center',
          sortable: false,
          value: 'product_dealer'
        }, {
          text: 'สถานะ',
          align: 'center',
          value: 'product_status',
          sortable: false
        }, {
          text: 'จัดการ',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      const self = this
      self.loaddata = true
      var temp = []
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + '/product/dealer', {
          headers: {
            Authorization: localStorage.getItem('Token') || self.$store.state.token
          }
        })
        if (res.status === 200) {
          res.data.result.data.map(el => {
            temp.push({
              _id: el._id,
              product_dealer: el.dealer,
              product_status: el.active ? 'active' : 'inactive',
              product_status_bool: el.active,
              created_at: el.create_at
            })
          })
          temp.sort((a, b) => {
            if (a.product_dealer < b.product_dealer) { return -1 }
            if (a.product_dealer > b.product_dealer) { return 1 }
            return 0
          })
        }
      } catch (error) {
        console.log(error.message)
      } finally {
        self.data = temp
        self.loaddata = false
      }
    },
    filterOnlyCapsText (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },
    addItem () {
      const self = this
      self.boolAddItems = true
      self.showDialog = true
    },
    viewItem (item) {
      const self = this
      self.dataDialog = item
      // console.log(self.dataDialog)
      self.boolViewItems = true
      self.showDialog = true
    },
    editItem (item) {
      const self = this
      self.dataDialog = item
      self.boolEditItems = true
      self.showDialog = true
    },
    deleteItem (item) {
      const self = this
      self.dataDialog = item
      self.boolDelItems = true
      self.showDialog = true
    },
    closeDialog () {
      const self = this
      self.boolAddItems = false
      self.boolViewItems = false
      self.boolEditItems = false
      self.boolDelItems = false
      self.showDialog = false
      self.dataDialog = {}
      self.fetchData()
      self.cansave = true
    },
    async submitDialog () {
      const self = this
      self.loaddata = true
      if (self.boolAddItems) {
        if (self.dataDialog?.product_dealer) {
          const obj = {
            dealer: self.dataDialog.product_dealer
          }
          self.cansave = false
          try {
            const res = await self.axios.post(process.env.VUE_APP_API + '/product/dealer', obj, {
              headers: {
                Authorization: localStorage.getItem('Token') || self.$store.state.token
              }
            })
            if (res.status === 200) {
              self.closeDialog()
              swal('สำเร็จ', 'เพิ่มผู้จำหน่ายยาและเวชภัณฑ์สำเร็จ', 'success', {
                button: false,
                timer: 2000
              })
            } else {
              swal('ผิดพลาด', 'เพิ่มผู้จำหน่ายยาและเวชภัณฑ์ไม่สำเร็จ กรุณาลองอีกครั้ง', 'error', {
                button: false,
                timer: 3000
              })
            }
          } catch (error) {
            console.log(error.message)
          } finally {
            self.cansave = true
          }
        } else {
          swal('คำเตือน', 'กรุณากรอกชื่อผู้จำหน่ายยาและเวชภัณฑ์', 'warning', {
            button: false,
            timer: 3000
          })
        }
      } else if (self.boolEditItems) {
        if (self.dataDialog?.product_dealer) {
          try {
            const obj = {
              dealer: self.dataDialog.product_dealer
            }
            self.cansave = false
            const res = await self.axios.put(`${process.env.VUE_APP_API}/product/dealer/${self.dataDialog._id}`, obj, {
              headers: {
                Authorization: localStorage.getItem('Token') || self.$store.state.token
              }
            })
            if (res.status === 200) {
              swal('สำเร็จ', 'แก้ไขผู้จำหน่ายยาและเวชภัณฑ์สำเร็จ', 'success', {
                button: false,
                timer: 2000
              })
            } else {
              swal('ผิดพลาด', 'แก้ไขผู้จำหน่ายยาและเวชภัณฑ์ไม่สำเร็จ กรุณาลองอีกครั้ง', 'error', {
                button: false,
                timer: 3000
              })
            }
          } catch (error) {
            swal('ผิดพลาด', 'แก้ไขผู้จำหน่ายยาและเวชภัณฑ์ไม่สำเร็จ กรุณาลองอีกครั้ง', 'error', {
              button: false,
              timer: 3000
            })
          } finally {
            self.cansave = true
            self.closeDialog()
          }
        } else {
          swal('คำเตือน', 'กรุณากรอกชื่อผู้จำหน่ายยาและเวชภัณฑ์', 'warning', {
            button: false,
            timer: 3000
          })
        }
      } else if (self.boolDelItems) {
        try {
          self.cansave = false
          const res = await self.axios.delete(`${process.env.VUE_APP_API}/product/dealer/${self.dataDialog._id}`, {
            headers: {
              Authorization: localStorage.getItem('Token') || self.$store.state.token
            }
          })
          if (res.status === 200) {
            swal('สำเร็จ', 'ลบผู้จำหน่ายยาและเวชภัณฑ์สำเร็จ', 'success', {
              button: false,
              timer: 2000
            })
          } else {
            swal('ผิดพลาด', 'ลบผู้จำหน่ายยาและเวชภัณฑ์ไม่สำเร็จ กรุณาลองอีกครั้ง', 'error', {
              button: false,
              timer: 3000
            })
          }
        } catch (error) {
          // console.log(error.message)
          swal('ผิดพลาด', 'ลบผู้จำหน่ายยาและเวชภัณฑ์ไม่สำเร็จ กรุณาลองอีกครั้ง', 'error', {
            button: false,
            timer: 3000
          })
        } finally {
          self.cansave = true
          self.closeDialog()
        }
      } else {
        self.closeDialog()
      }
    }
  }
}
</script>
